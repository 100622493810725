export class User {
  constructor(
    public id: number,
    public name: string,
    public email: string,
    public noRole: number,
    public password: string,
    public bBlocked: boolean,
    public bAssociation: boolean,
    public noPeriode: number,
    public noEntite: number,
    private _token: string,
    private tokenExpiration: string
  ) {}

  get token() {
    if (
      !this.tokenExpiration ||
      new Date(this.tokenExpiration) <= new Date()
    ) {
      return null;
    }
    return this._token;
  }

  get tokenDuration() {
    if (!this.token) {
      return 0;
    }
    return (
      new Date(this.tokenExpiration).getTime() - new Date().getTime()
    );
  }

  get tokenDurationDate() {
    if (!this.token) {
      return 0;
    }
    return new Date(this.tokenExpiration);
  }

  get tokenDurationString() {
    if (!this.token) {
      return '';
    }
    return this.tokenExpiration;
  }
}
