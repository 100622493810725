import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { User } from '../models/user.model';
import { environment } from '../../environments/environment';

export interface AuthResponseData { user: any; token: string; expires_at: string; }

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
    private _user = new BehaviorSubject<User>(null);
    private activeLogoutTimer: any;
        public   user : Observable          <User>;

    constructor( private http: HttpClient, private menuCtrl: MenuController) {}

        get enabled     () { return this .user .pipe (map (u => { return (u) ? !!u.token : false; })); }

    get userIsAuthenticated() { return this._user.asObservable().pipe( map(user => { if (user) { return !!user.token; } else { return false; } })); }
    get userId() { return this._user.asObservable().pipe( map(user => { if (user) { return user.id; } else { return null; } })); }
    get token() { return this._user.asObservable().pipe( map(user => { if (user) { return user.token; } else { return null; } })); }
    get noEntite() { return this._user.asObservable().pipe( map(user => { if (user) { return user.noEntite; } else { return null; } })); }
    get username() { return this._user.asObservable().pipe( map(user => { if (user) { return user.name; } else { return null; } })); }

    isAdmin() { return this._user.asObservable().pipe( map(user => { if (user) { return user.noRole == 9; } else { return false; } })); }

    isAssociation() { return this._user.asObservable().pipe( map(user => { if (user) { return user.noRole == 10; } else { return false; } })); }

    autoLogin() {
        return from(Plugins.Storage.get({ key: 'authData' })).pipe(
            map(storedData => { if (!storedData || !storedData.value) { return null; }
                const parsedData = JSON.parse(storedData.value) as { user: any; token: string; tokenExpirationDate: string; };
                const user = new User(
                    parsedData.user.id,
                    parsedData.user.name,
                    parsedData.user.email,
                    parsedData.user.noRole,
                    null,
                    parsedData.user.bBlocked == 0 ? false : true,
                    parsedData.user.bAssociation == 0 ? false : true,
                    parsedData.user.noPeriode,
                    parsedData.user.noEntite,
                    parsedData.user._token,
                    parsedData.user.tokenExpiration
                );
                if (user.tokenDurationDate <= new Date()) {
                    return null;
                }
                return user;
            }),
            tap(userData => { if (userData) { this._user.next(userData); this.autoLogout(userData.tokenDuration); }
            }),
            map(user => { return !!user; })
        );
    }

    // signup(user: User) {
    //   return this.http
    //       .post<AuthResponseData>(`${environment.baseUrlDB}register`, {
    //           name: user.name,
    //           email: user.email,
    //           no_role: user.noRole,
    //           password: user.password,
    //           password_confirmation: user.password,
    //           b_blocked: user.bBlocked,
    //           b_association: user.bAssociation,
    //           no_periode: user.noPeriode,
    //           no_entite: user.noEntite
    //       })
    //       .pipe(tap(this.setUserData.bind(this)));
    // }

    login(email: string, password: string) {
        return this.http
            .post<AuthResponseData>(`${environment.baseUrlDB}login`, { email, password })
            .pipe(tap(this.setUserData.bind(this)));
    }

    logout() {
        if (this.activeLogoutTimer) { clearTimeout(this.activeLogoutTimer); }
        this._user.next(null);
        this.menuCtrl.enable(false);
        Plugins.Storage.clear();
    }

    ngOnDestroy() {
        if (this.activeLogoutTimer) { clearTimeout(this.activeLogoutTimer); }
    }

    private autoLogout(duration: number) {
        if (this.activeLogoutTimer) { clearTimeout(this.activeLogoutTimer); }
        this.activeLogoutTimer = setTimeout(() => {
            this.logout();
        }, duration);
    }

    private setUserData(userData: AuthResponseData) {
        const loggedUser = userData.user;

        const user = new User(
            loggedUser.id,
            loggedUser.name,
            loggedUser.email,
            loggedUser.no_role,
            null,
            loggedUser.b_blocked == 0 ? false : true,
            loggedUser.b_association == 0 ? false : true,
            loggedUser.no_periode,
            loggedUser.no_entite,
            userData.token,
            userData.expires_at
        );
        this._user.next(user);

        const tokenDuration =
            new Date(userData.expires_at).getTime() - new Date().getTime();

        this.autoLogout(tokenDuration);
        this.storeAuthData(user, userData.token, userData.expires_at);
    }

    private storeAuthData(
        user: User,
        token: string,
        tokenExpirationDate: string
    ) {
        const data = JSON.stringify({
            user,
            token,
            tokenExpirationDate
        });

        Plugins.Storage.set({ key: 'authData', value: data });
    }

    refresh() {
        let currentToken = '';
        this.token.subscribe(token => {
            currentToken = token;
        });

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + currentToken
        });

        return this .http .get<{ message: string; resData: any }>(`${environment.baseUrlDB}authuser/refresh`, { headers })
            .pipe(tap(this.setUserData.bind(this)));
    }
}

// vim: nu et ts=4 sts=4 sw=4 fdm=indent fo-=l fo+=croq2 syn=javascript
