//! @file environments/environment.prod.ts
//! @brief environment.prod package
//! @namespace Environments
//! @class     Environments.EnvironmentProd

// This file can be replaced during build by using the `fileReplacements` array
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`
// The list of file replacements can be found in `angular.json`

export const CPIH_APPNAME = 'CPIH Recensement (UAT)';
export const CPIH_VERSION = '1.2.3';
export const CPIH_WEBSITE = 'https://cpih.ch';

export const CPIH_BE_HOST = 'https://cpih2023.isle.plus';
export const CPIH_BE_PATH = '/api/v1/';
export const CPIH_FE_HOST = 'https://cpih2023.isle.plus';
export const CPIH_FE_PATH = '/';

export const environment = {
    appname:               CPIH_APPNAME,
    version:               CPIH_VERSION,
    tag:                   'rc-20231016-0700',
    copyright:             { label: 'CPIH',            url: CPIH_WEBSITE, year: 2023 },
    credits:              [{ label: '(Report issues)', url: 'https://github.com/marcastel/pat-cpih-census/issues' },
                           { label: 'ISLE Suisse SA',  url: 'https://isle-cloud.ch' }],
    debug:                 true,
    production:            true,
    baseUrlDB:             CPIH_BE_HOST + CPIH_BE_PATH,
    baseUrlWeb:            CPIH_FE_PATH,
    reportBaseLink:        CPIH_FE_HOST + CPIH_FE_PATH,
    adminEmail:            'cpih@isle.plus',
    homepage:              CPIH_BE_HOST,
    backend:               CPIH_BE_HOST + CPIH_BE_PATH,

    sidenav     : [
        { title: 'Accueil',              url: CPIH_FE_PATH + 'home',              icon: 'home'                     },
        { title: 'Entreprises',          url: CPIH_FE_PATH + 'entreprises',       icon: 'business'                 },
        { title: 'Recensements',         url: CPIH_FE_PATH + 'recensements',      icon: 'analytics'                },
        { title: 'Admin',                children: [
        { title: 'Rôles',                url: CPIH_FE_PATH + 'roles',             icon: 'people',    type: 'admin' },
        { title: 'Périodes',             url: CPIH_FE_PATH + 'periodes',          icon: 'calendar',  type: 'admin' },
        { title: 'Utilisateurs',         url: CPIH_FE_PATH + 'users',             icon: 'person',    type: 'admin' },
        { title: 'Associations',         url: CPIH_FE_PATH + 'associations',      icon: 'wifi',      type: 'admin' },
        { title: 'Association',          url: CPIH_FE_PATH + 'association-edit',  icon: 'wifi',      type: 'assoc' },
        { title: 'Rapport',              url: CPIH_FE_PATH + 'rapport',           icon: 'document',  type: 'both'  },
        { title: 'Changer mot de passe', url: CPIH_FE_PATH + 'password',          icon: 'key',       type: 'both'  }]}]
};

// For easier debugging in development mode, you can import the following file to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
// This import should be commented out in production mode because it will have a negative impact on performance if an error is thrown.
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

// vim: nu et ts=4 sts=4 sw=4 fdm=marker fmr={,} fo-=l fo+=croq2 syn=javascript
