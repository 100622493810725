<ion-app>

  <ion-split-pane contentId="census-main">
    <ion-menu contentId="census-main" menuId="main-menu">
      <ion-header>
        <ion-toolbar><img src="assets/images/cpih.png" class="brand-icon" /></ion-toolbar>
        <ion-toolbar color="light"><ion-title>Menu</ion-title></ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <div *ngFor="let p of appPages">
            <!-- Menu sans enfant -->
            <ion-menu-toggle auto-hide="false" *ngIf="p.url && p.title !== 'Association'">
              <ion-item [routerDirection]="'root'" [routerLink]="[p.url]" routerLinkActive="active">
                <ion-icon slot="start" [name]="p.icon"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false" *ngIf="p.url && p.title == 'Association' && isAssociation() == true">
              <ion-item [routerDirection]="'root'" [routerLink]="[p.url]" routerLinkActive="active">
                <ion-icon slot="start" [name]="p.icon"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
            <!-- Lien avec enfants -->
            <ion-item button *ngIf="p.children?.length > 0 && p.title == 'Admin'" (click)="p.open = !p.open"
              [class.parent-active]="p.open" detail="false">
              <ion-icon slot="start" name="arrow-forward" *ngIf="!p.open"></ion-icon>
              <ion-icon slot="start" name="arrow-down" *ngIf="p.open"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>

            <!-- Enfants du lien pour admin -->
            <ion-list *ngIf="p.open && p.title == 'Admin'">
              <ion-menu-toggle auto-hide="false" *ngFor="let sub of p.children">
                <ion-item *ngIf="(sub.type == 'admin' || sub.type == 'both') && isAdmin()
                  " class="sub-item" [routerLink]="sub.url" routerDirection="root" routerLinkActive="active">
                  <ion-icon [name]="sub.icon" slot="start"></ion-icon>
                  <ion-label>
                    {{ sub.title }}
                  </ion-label>
                </ion-item>
                <ion-item *ngIf="
                    (sub.type == 'assoc' || sub.type == 'both') &&
                    isAssociation()
                  " class="sub-item" [routerLink]="sub.url" routerDirection="root" routerLinkActive="active">
                  <ion-icon [name]="sub.icon" slot="start"></ion-icon>
                  <ion-label>
                    {{ sub.title }}
                  </ion-label>
                </ion-item>
              </ion-menu-toggle>
            </ion-list>
          </div>
        </ion-list>
      </ion-content>
      <ion-footer class="ion-text-center" color="light" style="padding-bottom: 5px">
        <a href="{{ env.homepage }}" style="text-decoration: none">v{{ env.version }} {{ env.tag }}</a></ion-footer>
    </ion-menu>
    <ion-router-outlet main id="census-main"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
<!-- vim: set nu et tw=130 ts=2 sts=2 sw=2 fdm=marker fmr=@{,@} fo-=l fo+=croq2 :-->
