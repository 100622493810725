//! @file main.ts
//! @brief This is the entry point used by Angular to launch and bootstrap the application.

import { enableProdMode         } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule              } from './app/app.module';
import { environment            } from './environments/environment';

// don (Fri Oct 16 17:23:09 CEST 2020) disabled
// import 'hammerjs';

if (environment.production) enableProdMode();
platformBrowserDynamic () .bootstrapModule (AppModule) .catch (err => console.log (err));
//  don (Fri Oct 16 11:40:26 CEST 2020) Cf. Disabling Zone (https://angular.io/guide/zone)
//platformBrowserDynamic () .bootstrapModule (AppModule, { ngZone: 'noop' }) .catch(err => console.error(err));

/// New from here

// import './polyfills';
// import {HttpClientModule} from '@angular/common/http';
// import {NgModule} from '@angular/core';
// import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// import {MatNativeDateModule} from '@angular/material/core';
// import {BrowserModule} from '@angular/platform-browser';
// import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
// import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
// import {DemoMaterialModule} from './app/material-module';
// import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
// import {FormFieldAppearanceExample} from './app/form-field-appearance-example';
// 
// // Default MatFormField appearance to 'fill' as that is the new recommended approach and the
// // `legacy` and `standard` appearances are scheduled for deprecation in version 10.
// // This makes the examples that use MatFormField render the same in StackBlitz as on the docs site.
// @NgModule({
//   imports: [BrowserModule, BrowserAnimationsModule, FormsModule, HttpClientModule, DemoMaterialModule, MatNativeDateModule, ReactiveFormsModule, ],
//   entryComponents: [FormFieldAppearanceExample],
//   declarations: [FormFieldAppearanceExample],
//   bootstrap: [FormFieldAppearanceExample],
//   providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
//   ]
// })
// export class AppModule {}
// platformBrowserDynamic().bootstrapModule(AppModule) .catch(err => console.error(err));


// vim: nu et tw=130 ts=4 sts=4 sw=4 ff=unix fo-=l fo+=tcroq2 syn=javascript
