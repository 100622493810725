//translate-config.service.ts
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {
  constructor(private translate: TranslateService) {}

  getDefaultLanguage() {
    const language = 'fr';
    this.translate.setDefaultLang(language);
    this.translate.use(language);
    return language;
  }

  setLanguage(setLang) {
    this.translate.use(setLang);
  }

  getCurrentLanguage() {
    return this.translate.currentLang;
  }
}
