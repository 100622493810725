import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {
    Router,
    Event,
    NavigationStart,
    NavigationEnd,
    NavigationError,
    GuardsCheckStart
} from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Platform } from '@ionic/angular';
import { Plugins, Capacitor } from '@capacitor/core';

@Component ({ selector: 'app-root', templateUrl: 'app.component.html' })

export class AppComponent implements OnInit, OnDestroy {
    private authSub: Subscription;
    private refreshSub: Subscription;
    private routerSub: Subscription;
    previousAuthState = false;

    public env: any;
    public appPages = [{ title: 'Accueil', url: '/home', icon: 'home' },
        { title: 'Entreprises', url: '/entreprises', icon: 'business' },
        { title: 'Recensements', url: '/recensements', icon: 'analytics' },
        { title: 'Admin',
            children: [
                {
                    title: 'Rôles',
                    url: '/roles',
                    icon: 'people',
                    type: 'admin'
                },
                {
                    title: 'Périodes',
                    url: '/periodes',
                    icon: 'calendar',
                    type: 'admin'
                },
                {
                    title: 'Utilisateurs',
                    url: '/users',
                    icon: 'person',
                    type: 'admin'
                },
                {
                    title: 'Associations',
                    url: '/associations',
                    icon: 'wifi',
                    type: 'admin'
                },
                {
                    title: 'Association',
                    url: '/association-edit',
                    icon: 'wifi',
                    type: 'assoc'
                },
                {
                    title: 'Rapport',
                    url: '/rapport',
                    icon: 'document',
                    type: 'both'
                },
                {
                    title: 'Changer mot de passe',
                    url: '/password',
                    icon: 'key',
                    type: 'both'
                }
            ]
        }
    ];

    constructor(
        private platform: Platform,
        private authService: AuthService,
        private router: Router,
        private translate: TranslateService
    ) {
        this.initializeApp();
        this.routerSub = this.router.events.subscribe((event: Event) => {
            if (event instanceof GuardsCheckStart) {
                if (this.router.url !== '/') {
                    this.refreshSub = this.authService
                        .refresh()
                        .pipe(take(1))
                        .subscribe(() => {}, error => console.error(error));
                }
            }
        });
    }

    ngOnInit() {
        this .env = environment;
        this.authSub = this.authService.userIsAuthenticated.subscribe(
            isAuth => {
                if (!isAuth && this.previousAuthState !== isAuth) {
                    this.router.navigateByUrl('/auth');
                }
                this.previousAuthState = isAuth;
            }
        );
    }

    initializeApp() {
        this.platform.ready().then(() => {
            if (Capacitor.isPluginAvailable('SplashScreen')) {
                Plugins.SplashScreen.hide();
            }
        });
    }

    isAdmin(): boolean {
        let isAdmin: boolean;
        this.authService.isAdmin().subscribe(value => {
            isAdmin = value;
        });
        return isAdmin;
    }

    isAssociation(): boolean {
        let isAssociation: boolean;
        this.authService.isAssociation().subscribe(value => {
            isAssociation = value;
        });
        return isAssociation;
    }

    ngOnDestroy() {
        if (this.authSub) {
            this.authSub.unsubscribe();
        }
    }
}

// vim: nu et ts=4 sts=4 sw=4 fdm=marker fmr={,} fo-=l fo+=croq2 syn=javascript
