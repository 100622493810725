import { NgModule } from '@angular/core';
import {
  PreloadAllModules,
  RouterModule,
  Routes
} from '@angular/router';

import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule',
    canLoad: [AuthGuard]
  },
  { path: 'auth', loadChildren: './auth/auth.module#AuthPageModule' },
  {
    path: 'periodes',
    loadChildren:
      './admin/periodes/periodes.module#PeriodesPageModule',
    canLoad: [AuthGuard]
  },
  {
    path: 'roles',
    loadChildren: './admin/roles/roles.module#RolesPageModule',
    canLoad: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: './admin/users/users.module#UsersPageModule',
    canLoad: [AuthGuard]
  },
  {
    path: 'rapport',
    loadChildren: './admin/rapport/rapport.module#RapportPageModule',
    canLoad: [AuthGuard]
  },
  {
    path: 'password',
    loadChildren:
      './admin/password/password.module#PasswordPageModule',
    canLoad: [AuthGuard]
  },
  {
    path: 'entreprises',
    loadChildren:
      './entreprises/entreprises.module#EntreprisesPageModule',
    canLoad: [AuthGuard]
  },
  {
    path: 'entreprises',
    loadChildren:
      './entreprises/entreprises.module#EntreprisesPageModule'
  },
  {
    path: 'entreprise-edit',
    children: [
      {
        path: '',
        loadChildren:
          './entreprises/entreprise-edit/entreprise-edit.module#EntrepriseEditPageModule',
        canLoad: [AuthGuard]
      },
      {
        path: ':noEntreprise',
        loadChildren:
          './entreprises/entreprise-edit/entreprise-edit.module#EntrepriseEditPageModule',
        canLoad: [AuthGuard]
      }
    ]
  },
  {
    path: 'associations',
    loadChildren:
      './admin/associations/associations.module#AssociationsPageModule',
    canLoad: [AuthGuard]
  },
  {
    path: 'association-edit',
    children: [
      {
        path: '',
        loadChildren:
          './admin/associations/association-edit/association-edit.module#AssociationEditPageModule',
        canLoad: [AuthGuard]
      },
      {
        path: ':noAssociation',
        loadChildren:
          './admin/associations/association-edit/association-edit.module#AssociationEditPageModule',
        canLoad: [AuthGuard]
      }
    ]
  },
  {
    path: 'recensements',
    loadChildren:
      './recensements/recensements.module#RecensementsPageModule',
    canLoad: [AuthGuard]
  },
  {
    path: 'recensement-edit/:link',
    loadChildren:
      './recensements/recensement-edit/recensement-edit.module#RecensementEditPageModule'
  },
  {
    path: 'recensement-detail/:noRecensement',
    loadChildren:
      './recensements/recensement-detail/recensement-detail.module#RecensementDetailPageModule',
    canLoad: [AuthGuard]
  },
  {
    path: 'rapport',
    loadChildren: './admin/rapport/rapport.module#RapportPageModule'
  },
  {
    path: 'password',
    loadChildren:
      './admin/password/password.module#PasswordPageModule'
  },
  {
    path: 'unsubscribe/:link',
    loadChildren:
      './unsubscribe/unsubscribe.module#UnsubscribePageModule'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
